import React, { FC } from "react";
import './TopMenu.css'
import { useNavigate } from "react-router-dom";
import { datastore } from "../../datastore";
import { useTranslation } from 'react-i18next';

export enum TopMenuItem {
  LeadList,
  PartnerAgreements,
  Settings,
  None
}

export enum viewType {
  Lead,
  EstateBrokerOffice,
  EstateBroker,
  NOTUSED
}

export interface TopMenuProps {
  selectedItem: TopMenuItem
  view: viewType
}

const TopMenu: FC<TopMenuProps> = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleItemClicked = (item: TopMenuItem) => {
    switch (item) { 
      case TopMenuItem.LeadList:
        navigate('/leads');
        break;
      case TopMenuItem.Settings:
        navigate('/settings');
        break;
      case TopMenuItem.PartnerAgreements:
        navigate('/partnerAgreements');
        break;

      default:
        break;
    }
  }

  return (
    <div className="MainMenu">
      {props.view === viewType.NOTUSED && (
        <>
          <input id="newOrder" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.None ? true : false} onClick={() => handleItemClicked(TopMenuItem.None)} />
          <input id="orderList" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.LeadList ? true : false} onClick={() => handleItemClicked(TopMenuItem.LeadList)} />
          <input id="settings" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.Settings ? true : false} onClick={() => handleItemClicked(TopMenuItem.Settings)} />

          <div id="tabs">
            <label id="tab1" htmlFor="newOrder">
              Ny bestilling
            </label>
            <label id="tab2" htmlFor="orderList">
              Bestillinger
            </label>
            <label id="tab3" htmlFor="settings">
              Indstillinger
            </label>
          </div>
        </>
      )}
      {props.view === viewType.Lead && (
        <>
          <input id="authorOrderList" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.LeadList ? true : false} onClick={() => handleItemClicked(TopMenuItem.LeadList)} />
          <div id="tabs">
            <label id="tab1" htmlFor="authorOrderList">
              {t('TopMenu_Orders')}
            </label>
          </div>
        </>
      )}
      {props.view === viewType.EstateBroker && (
        <>
          <input id="authorOrderList" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.LeadList ? true : false} onClick={() => handleItemClicked(TopMenuItem.LeadList)} />
          <input id="partnerAgreements" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.PartnerAgreements ? true : false} onClick={() => handleItemClicked(TopMenuItem.PartnerAgreements)} />
          <div id="tabs">
            <label id="tab1" htmlFor="authorOrderList">
              {t('TopMenu_Orders')}
            </label>
            <label id="tab2" htmlFor="partnerAgreements">
              Aftaler
            </label>
          </div>
        </>
      )}
      {props.view === viewType.EstateBrokerOffice && (
        <>
          <input id="authorOrderList" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.LeadList ? true : false} onClick={() => handleItemClicked(TopMenuItem.LeadList)} />
          <div id="tabs">
            <label id="tab1" htmlFor="authorOrderList">
              {t('TopMenu_Orders')}
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default TopMenu;