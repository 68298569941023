import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { datastore } from "../../datastore";
import { useQuery, gql } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { Card, CardContent } from "@mui/material";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";

const GET_PARTNER_DETAILS = gql`
  query affiliatePartners {
    affiliatePartners {
      name
      product {
        id
        name
        prices {
            id
            fromDate
            toDate
            realEstateBrokerEarning
            realEstateSystemEarning
            earning
            billingType
            salesPriceLessThan
            salesPriceGreaterThan
        }
      }
    }
  }
`;

const PartnerAgreements: React.FC = () => {

    const { loading, error, data } = useQuery(GET_PARTNER_DETAILS, {
        fetchPolicy: 'network-only'
    });

    if (loading) {
        return <div></div>;
    }

    if (error) {
        //return <Error errorCode={500} />;
    }

    if (!data) {
        //return (<Error errorCode={500} />);
    }

    const getMenuView = () => {
        switch (datastore.data.userType) {
            case 'RealEstateBroker':
                return viewType.EstateBroker;
            case 'AffiliatePartner':
                return viewType.Lead;
            case 'RealEstateBrokerOffice':
                return viewType.EstateBrokerOffice;
            default:
                return viewType.Lead;
        }
    }

    let columns: GridColDef[] = [
        { field: 'affiliatePartnerName', headerName: 'Partner', minWidth: 200, align: 'left' },
        { field: 'productName', headerName: 'Produkt', minWidth: 300, flex: 1 },
        { field: 'realEstateBrokerEarning', headerName: 'Ejendomsmægler', minWidth: 140, flex: 0, align: 'center', headerAlign: 'center' },        
    /*    { field: 'realEstateSystemEarning', headerName: 'Mæglersystem', minWidth: 140, flex: 0, align: 'center', headerAlign: 'center' },
        { field: 'earning', headerName: 'Settle', width: 140,flex: 0, align: 'center', headerAlign: 'center' },*/
    ];

    let rows = [] as any;
    //rows = data.affiliatePartners.map((affiliatePartner: any) => {

    for (let i = 0; i < data.affiliatePartners.length; i++) {

        const affiliatePartner = data.affiliatePartners[i];
        //return affiliatePartner.products.map((product: any) => {
            
        const product = affiliatePartner.product;

        if (!product) {
            rows.push({ 
                id: 0,
                affiliatePartnerName: affiliatePartner.name,
                productName: '',
                productId: 0,
                realEstateSystemEarning: 0,
                realEstateBrokerEarning: 0,
                earning: 0
            })
        }

        //find the price for the product by the current date
        //let price = product.prices.find((price: any) => {
        //    return new Date(price.fromDate) <= new Date() && new Date(price.toDate) >= new Date();          
        //});

        //find the price for the product by newest date

        //console.log('product:', product.prices);

        console.log('product:', product);
        if (!product.prices)   {
            //console.log('no prices:', product);
            rows.push( {
                id: product.id,
                affiliatePartnerName: affiliatePartner.name,
                productName: product.name,
                productId: product.id,
                realEstateSystemEarning: 0,
                realEstateBrokerEarning: 0,
                earning: 0
            })
        }

        if (product.prices.length === 0) {
            //console.log('no prices:', product);
            rows.push( {
                id: product.id,
                affiliatePartnerName: affiliatePartner.name,
                productName: product.name,
                productId: product.id,
                realEstateSystemEarning: 0,
                realEstateBrokerEarning: 0,
                earning: 0
            })
        }

        //find all prices that are active
        let prices = product.prices.filter((price: any) => {
            return new Date(price.fromDate) <= new Date() && new Date(price.toDate) >= new Date();
        });

        console.log('prices:', prices.length)
        if (prices.length === 0) {
            rows.push( {
                id: product.id,
                affiliatePartnerName: affiliatePartner.name,
                productName: product.name,
                productId: product.id,
                realEstateSystemEarning: 0,
                realEstateBrokerEarning: 0,
                earning: 0
            })
        }else{
            for (let i = 0; i < prices.length; i++) {
                const price = prices[i];

                let procent = ""
                let priceInterval = ""
                if (price.billingType.KICKBACK_ORDER_PROCENT){
                    procent = " %"
                }

                if (price.billingType.KICKBACK_ORDER_INTERVAL){
                    priceInterval = "Salg kr. (" + price.salesPriceLessThan + " - " + price.salesPriceMoreThan + ")"
                }
    
                rows.push( {
                    id: product.id,
                    affiliatePartnerName: affiliatePartner.name,
                    productName: product.name,
                    productId: product.id,
                    realEstateSystemEarning: priceInterval + price.realEstateSystemEarning + procent,
                    realEstateBrokerEarning: priceInterval + price.realEstateBrokerEarning + procent,
                    earning: priceInterval + price.earning + procent
                })
            }
        }
    };

    //remove id = 0
    rows = rows.filter((item: any) => item.id !== 0);

    //flatten the array
    rows = rows.flat(2);
    //remove duplicates by id
    rows = rows.filter((item: any, index: any, self: any) =>
        index === self.findIndex((t: any) => (
            t.id === item.productId
        ))
    );

    return (
        <div className="orderList MainArea">
            <header className="TopAreaMain">
                <Logo />
                <TopMenu selectedItem={TopMenuItem.LeadList} view={getMenuView()} />
            </header>
            <Grid container spacing={0}>
                <Grid xs={12}>
                    <div className="contentContainer">
                        <div className="Heading">Partner aftaler</div>
                        <Grid container spacing={0}>
                            <Grid xs={12} style={{ height: '100vh', width: '100%', borderWidth: '0px' }}>

                                <Paper elevation={0} >
                                    <Card>

                                        <CardContent>

                                            <DataGrid
                                                style={{ borderWidth: '0px' }}

                                                rows={rows}
                                                columns={columns}
                                                initialState={{
                                                    filter: {
                                                        filterModel: {
                                                            items: [],
                                                            quickFilterValues: [''],
                                                        },
                                                    },
                                                }}
                                                autoHeight={true}
                                                disableColumnFilter
                                                disableColumnSelector
                                                disableDensitySelector
                                                slots={{ toolbar: GridToolbar }}
                                                slotProps={{
                                                    toolbar: {
                                                        showQuickFilter: true,
                                                    },
                                                }}
                                                sx={{
                                                    // disable cell selection style
                                                    '.MuiDataGrid-cell:focus': {
                                                        outline: 'none'
                                                    },
                                                    // pointer cursor on ALL rows
                                                    '& .MuiDataGrid-row:hover': {
                                                        cursor: 'pointer'
                                                    }
                                                }}
                                            />

                                        </CardContent>
                                    </Card>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )

}

export default PartnerAgreements;