import React from "react";
import Error from "../error/error";
import Grid from "@mui/material/Unstable_Grid2";
import "./leadInfo.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import Paper from '@mui/material/Paper';
import { Input } from '@mui/material';
import { useParams } from "react-router-dom";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Button, Card, CardContent, CardHeader, Snackbar } from "@mui/material";



const UPDATE_LEAD = gql`
  mutation updateLead($input: UpdateLead!) {
    updateLead(input: $input) {
      id
    }
  }
`;


const UPDATE_LEAD_AS_APPROVED = gql`
  mutation setLeadAsApproved($leadId: ID!) {
    setLeadAsApproved(leadId: $leadId) 
  }
`;

const UPDATE_LEAD_AS_REJECTED = gql`
  mutation setLeadAsRejected($leadId: ID!) {
    setLeadAsRejected(leadId: $leadId) 
  }
`;

const GET_LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id   
      price
      affiliatePartnerProduct {
        id
        name
        prices {
          id
          billingType
        }
      }
      movingDetails {
        takeoverDate
        customerType
        moveToAddress {
          fullAddress
        }
      }
      customer {
        id 
        name
        phone
        email
        cvr
      }
      status
      externalOrderId
      externalRef
      documents {
        id
        filename
      }
      logs {
        id
        activity
        type
        source
        info
        value
        server
        created
      }
      status
      createdAt
      updatedAt
    }
  }
`;

const LeadInfo: React.FC = () => {
  const { id } = useParams();
  const [openCopyToClipboardInfo, setOpenCopyToClipboardInfo] = React.useState(false);
  const [openSavedInfo, setOpenSavedInfo] = React.useState(false);
  const [openNoteSavedInfo, setOpenNoteSavedInfo] = React.useState(false);
  const [openErrorInfo, setOpenErrorInfo] = React.useState(false);
  const [currentNoteText, setCurrentNoteText] = React.useState("");
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [SelectedImage, setSelectedImage] = React.useState(0);
  const [updateLeadAsApproved] = useMutation(UPDATE_LEAD_AS_APPROVED);
  const [updateLeadAsRejected] = useMutation(UPDATE_LEAD_AS_REJECTED);
  const [updateLead] = useMutation(UPDATE_LEAD);

  const thumbnailsRef = React.useRef(null);

  const { loading, error, data } = useQuery(GET_LEAD, {
    variables: {
      id: id
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <Error errorCode={500} />;
  }

  if (!data) {
    return (<Error errorCode={500} />);
  }

  const translateStatus = (status: string) => {
    switch (status) {
      case "DECLINED_PROCESSED":
        return "Afvist";
      case "ACCEPTED_PROCESSED":
        return "Godkendt";
      default:
        return "Ukendt";
    }
  }

  const handleLeadAccepted = (event: any) => {
    updateLeadAsApproved({ variables: { leadId: data.lead.id }, refetchQueries: [{ query: GET_LEAD, variables: { id: id } }] })
      .catch((error) => {
        console.log('Error updating lead:', error);
        setOpenErrorInfo(true);
      });
  }

  const handleLeadRejected = (event: any) => {
    updateLeadAsRejected({ variables: { leadId: data.lead.id }, refetchQueries: [{ query: GET_LEAD, variables: { id: id } }] })
      .catch((error) => {
        console.log('Error updating lead:', error);
        setOpenErrorInfo(true);
      });
  }

  const handleErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenErrorInfo(false);
  };

  const handleInfoClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSavedInfo(false);
  };

  const showPriceField = () => {
    for (let i = 0; i < data.lead.affiliatePartnerProduct.prices.length; i++) {
      const priceItem = data.lead.affiliatePartnerProduct.prices[i];
      if (priceItem.billingType === 'KICKBACK_ORDER_INTERVAL' || priceItem.billingType === 'KICKBACK_ORDER_PROCENT') {
        return true
      }
    }

    return false;
  }

  const handlePriceChange = (event: any) => {
    let value = event.target.value;
    if (value === "") {
      value = 0
    }

    updateLead({
      variables: {
        input: {
          id: data.lead.id,
          price: parseInt(value)
        }
      }
    })

  }

  return (
    <div className="orderInfo MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.LeadList} view={viewType.Lead} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Lead</div>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Paper elevation={0} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Lead information'} />
                    <CardContent>
                      <div>
                        <div className="item">
                          <div className="itemLabel">Status:</div>
                          {translateStatus(data.lead.status) === "Ukendt" ? (
                            <>
                              <Button onClick={handleLeadAccepted} variant="outlined" color="primary" size="small" style={{ width: '120px' }}>Godkend</Button>
                              <span style={{ width: '10px' }}></span>
                              <Button onClick={handleLeadRejected} variant="outlined" color="secondary" size="small" style={{ width: '120px' }}>Afvis</Button>
                            </>
                          ) : (
                            <div className="itemData">{translateStatus(data.lead.status)}</div>
                          )}
                        </div>
                        {showPriceField() && (
                          <div className="item">
                            <div className="itemLabel">Salg kr.:</div>
                            <Input type={'number'} defaultValue={data.lead.price} style={{ width: '250px' }} onBlur={handlePriceChange} />
                          </div>
                        )}
                        <div className="item">
                          <div className="itemLabel">Reference id:</div>
                          <div className="itemData">{data.lead.externalOrderId}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Produkt:</div>
                          <div className="itemData">{data.lead.affiliatePartnerProduct.name}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Oprettet:</div>
                          <div className="itemData"> {data.lead.createdAt.split('T')[0]}</div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                </Paper>
              </Grid>
              <Grid xs={6}>
                <Paper elevation={0} style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Kunde information'} />
                    <CardContent>
                      <div>
                        <div className="item">
                          <div className="itemLabel">Navn:</div>
                          <div className="itemData">{data.lead.customer.name}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Telefon nr.:</div>
                          <div className="itemData">{data.lead.customer.phone}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">E-mail:</div>
                          <div className="itemData">{data.lead.customer.email}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">CVR:</div>
                          <div className="itemData">{data.lead.customer.cvr}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Flytter til:</div>
                          <div className="itemData">{data.lead.movingDetails.moveToAddress.fullAddress}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Overtagelsesdato:</div>
                          <div className="itemData">{data.lead.movingDetails.takeoverDate.split('T')[0]}</div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid xs={12}>
                <Paper elevation={0} style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Log'} />
                    <CardContent>
                      <table width={'100%'} >
                        <thead>
                          <tr>
                            <th align="left">
                              Dato
                            </th>
                            <th align="left">
                              Hændelse
                            </th>
                            <th align="left">
                              Type
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.lead.logs.map((log: any) => (
                            <tr key={log.id}>
                              <td width={200}>
                                <div >
                                  {log.created.split('T')[0]}
                                </div>
                              </td>
                              <td>
                                <div >
                                  {log.activity}
                                </div>
                              </td>
                              <td width={100}>
                                <div >
                                  {log.type}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>


            </Grid>
          </div>
        </Grid>
      </Grid>

      <Snackbar
        open={openSavedInfo}
        autoHideDuration={2000}
        message="Ændringen er blevet gemt."
        onClose={handleInfoClose}
      />

      <Snackbar
        open={openErrorInfo}
        autoHideDuration={2000}
        ContentProps={{
          sx: {
            background: "red"
          }
        }}
        message="Opdatering af ordren fejlede."
        onClose={handleErrorClose}
      />

    </div>
  );
};

export default LeadInfo;
